import { Link } from "react-router-dom";
import { IGameResult } from "../api/main/mainApi";

type PropType = {
  gameData: IGameResult | undefined;
};

const ListItem = (props: PropType) => {
  const { gameData } = props;

  return (
    <div className="grid grid-cols-2 gap-x-2 gap-y-[34px] w-full md:grid-cols-3 md:gap-x-6 md:gap-y-9 xl:grid-cols-4 xl:gap-x-[30px] xl:gap-y-14">
      {gameData?.contents?.map((game) => (
        <div key={game.id}>
          <Link to={`/games/${game.id}`}>
            <div
              className="bg-neutral-300 relative w-full aspect-square bg-cover bg-center bg-no-repeat cursor-pointer rounded-sm mb-3 shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:mb-4 xl:mb-[14px]"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_CF_URL}${game.bannerUrl})`,
              }}
            ></div>
          </Link>
          <div className="flex flex-col">
            <div className="flex flex-col md:hidden">
              <span className="text-sm font-bold pb-1">{game.title}</span>
              <span className="text-[10px] text-neutral-500">
                {game.gameCompany}
              </span>
            </div>
            <div className="hidden md:flex flex-col">
              <span className="text-sm font-bold pb-1 pl-0.5 md:pb-0.5 xl:text-lg xl:mb-1">
                {game.title}
              </span>
              <span className="text-[10px] pl-0.5  text-neutral-500 md:pb-2 xl:text-sm">
                {game.gameCompany}
              </span>
              <span className="text-[10px] pl-0.5 text-neutral-700 xl:text-base">
                {game.subDescription}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ListItem;
