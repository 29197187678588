import React, { useCallback, useEffect } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";

type PropType = {
  options?: EmblaOptionsType;
  slides: JSX.Element[] | undefined;
};

export const CategoriesCarousel = (props: PropType) => {
  const { options, slides } = props;
  const [emblaRef, embla] = useEmblaCarousel(options);

  const onSelect = useCallback(() => {
    if (!embla) return;
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
    window.addEventListener("resize", () => onSelect());
  }, [embla, onSelect]);

  if (typeof slides !== "undefined") {
    return (
      <>
        <div
          className="relative h-full overflow-visible xl:overflow-hidden xl:w-[980px] z-20"
          ref={emblaRef}
        >
          <div className="flex flex-row h-full">
            {slides?.map((slide, index) => (
              <div className="h-full relative mr-[22px] md:mr-8 xl:mr-16" key={index}>
                {slide}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};

export default CategoriesCarousel;
