import { Link } from "react-router-dom";

interface SlideProps {
  id: number;
  title: string;
  gameCompany: string;
  subDescription: string;
  bannerUrl: string;
  totSurveyors: number;
}

export default function RecommandCarouselSlide({
  id,
  title,
  gameCompany,
  subDescription,
  bannerUrl,
  totSurveyors,
}: SlideProps) {
  return (
    <div className="flex flex-col" key={id}>
      <Link to={`/games/${id}`}>
        <div
          className="w-full aspect-[3/4] bg-neutral-300 bg-cover bg-center bg-no-repeat relative cursor-pointer rounded-sm shadow-[0px_4px_4px_rgba(0,0,0,0.25)] mb-2.5 xl:mb-2"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_CF_URL}${bannerUrl})`,
          }}
        >
          <div className="w-full aspect-[3/1] absolute bottom-0 left-0 bg-gradient-to-b rounded-b-sm from-[#72727200] to-[#161616] opacity-70"></div>
          <span className="absolute bottom-3 left-2 text-neutral-50 text-sm font-bold xl:text-xl xl:bottom-2.5 xl:left-3">
          {title.length < 8 ? title : title.slice(0,8)+"..."}
          </span>
          <span className="text-xs absolute bottom-3 right-2 font-bold text-inssa-teal xl:text-base xl:bottom-2.5 xl:right-3">
            {totSurveyors}명 참여
          </span>
        </div>
      </Link>
      <span className="text-[10px] text-neutral-500 font-medium pb-1.5 xl:pb-2 xl:text-sm">
        {gameCompany}
      </span>
      <span className="font-medium text-xs text-neutral-700 xl:text-base">
        {subDescription}
      </span>
    </div>
  );
}
