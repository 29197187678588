import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link, useParams } from "react-router-dom";
import GameDetailCarousel from "../../components/games/detail/gameDetailCarousel";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  IGameDetail,
  IGameQuestion,
  getGameDetail,
  getGameQuestion,
  postSurvey,
} from "../../api/games/gameDetailApi";
import Loader from "../../components/Loader";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 100,
    borderRadius: "8px",
  },
};

const logo = require("../../assets/images/logo.png");

const GameDetail = () => {
  const path = useParams();
  const [choiceQuestion, setChoiceQuestion] = useState<number[]>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postFail, setPostFail] = useState("");
  const { register, handleSubmit, getValues } = useForm();

  const { mutate } = useMutation(postSurvey, {
    onSuccess: (data) => {
      if (data.success) {
        setPostSuccess(true);
      } else {
        setPostFail(data.message);
      }
    },
  });

  const { data: gameData, isFetching: gameFetching } = useQuery<IGameDetail>(
    "getGames",
    () => getGameDetail(path.gameId as string)
  );

  const { data: questionData, isFetching: questionFetching } =
    useQuery<IGameQuestion>("getQuestions", () => getGameQuestion());

  const faAppleBrand = faApple as IconProp;
  const faGooglePlayBrand = faGooglePlay as IconProp;

  const clickChoice = (choiceId: number) => {
    if (choiceQuestion.includes(choiceId)) {
      setChoiceQuestion(choiceQuestion.filter((choice) => choice !== choiceId));
    } else {
      let newArray = choiceQuestion;
      newArray.push(choiceId);
      setChoiceQuestion(newArray);
    }
  };

  const onValid = () => {
    const { phone, agreement } = getValues();

    const newChoiceQuestion: any[] = [];

    if (choiceQuestion.length === 0) return;
    choiceQuestion.map((data) => {
      newChoiceQuestion.push({
        questionId: 4,
        choiceId: data,
      });
    });
    const formData = {
      gameId: path.gameId,
      phone,
      agreements: agreement,
      survey: newChoiceQuestion,
    };

    mutate(formData);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {gameFetching || questionFetching ? (
        <Loader />
      ) : (
        <>
          <div className="bg-black text-neutral-50">
            <section
              className="bg-cover bg-center bg-no-repeat relative"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_CF_URL}${gameData?.bgImg})`,
              }}
            >
              <div className="flex flex-col xl:items-center">
                <nav className="flex justify-center pt-[52px] mb-[30px] z-10 md:mb-[140px] xl:w-100 xl:justify-start xl:pt-[44px] xl:mb-[230px]">
                  <Link to={"/"}>
                    <img
                      src={logo}
                      alt="logo"
                      className="w-[76px] h-[40px] md:w-[116px] md:h-[60px] xl:w-[156px] xl:h-[78px]"
                    />
                  </Link>
                </nav>
                <div className="px-4 flex flex-col z-10 md:px-6 xl:w-100 xl:px-0">
                  <span className="text-inssa-teal text-xl font-bold mb-[18px] md:text-[32px] xl:text-6xl xl:mb-14">
                    {gameData?.expiredAt !== null &&
                    gameData?.expiredAt !== undefined
                      ? `마감일 ${
                          gameData?.expiredAt && gameData?.expiredAt.slice(5, 7)
                        }.${
                          gameData?.expiredAt &&
                          gameData?.expiredAt.slice(8, 10)
                        }`
                      : ""}
                  </span>
                  <div className="text-neutral-400 text-xs mb-1.5 font-bold xl:text-2xl xl:mb-7">
                    {gameData?.googlePlayUrl && (
                      <span className="">Android</span>
                    )}
                    {gameData?.appStoreUrl && <span className="">| IOS</span>}
                    <span className=""> 지원</span>
                  </div>
                  <span className="text-4xl font-bold mb-[18px] md:mb-5 xl:text-[86px] xl:leading-[90px] xl:mb-10">
                    {gameData?.title}
                  </span>
                  <div className="flex flex-col font-bold text-neutral-100 text-sm mb-2.5 md:text-xl md:mb-3 xl:text-2xl xl:mb-[22px]">
                    {gameData?.subDescription &&
                      gameData.subDescription
                        .split("\n")
                        .map((data) => <span>{data}</span>)}
                  </div>
                  <span className="text-[10px] text-neutral-400 mb-[30px] md:text-xs md:mb-[50px] xl:text-base xl:mb-9">
                    {gameData?.genres?.map((genre) => (
                      <span className="text-[10px] text-neutral-400 mb-[30px] md:text-xs md:mb-[50px] xl:text-base xl:mb-9">
                        {`#${genre} `}
                      </span>
                    ))}
                  </span>
                </div>
                <div className="flex mb-[34px] px-4 z-10 md:mb-12 md:px-6 xl:w-100 xl:px-0 xl:mb-[60px]">
                  {gameData?.googlePlayUrl && (
                    <Link to={gameData.googlePlayUrl} target="_blank">
                      <div className="w-[134px] h-9 flex justify-center items-center border-[1.5px] border-inssa-teal rounded-md text-inssa-teal text-xs font-medium mr-1.5 md:w-[180px] md:h-[50px] md:text-lg md:mr-[18px] xl:text-2xl xl:w-[250px] xl:h-[70px] xl:rounded-xl xl:mr-7 xl:border-2">
                        <FontAwesomeIcon
                          icon={faGooglePlayBrand}
                          size="lg"
                          className="mr-1 xl:mr-2.5"
                        />
                        <span>Google play</span>
                      </div>
                    </Link>
                  )}
                  {gameData?.appStoreUrl && (
                    <Link to={gameData.appStoreUrl} target="_blank">
                      <div className="w-[134px] h-9 flex justify-center items-center border-[1.5px] border-inssa-teal rounded-md text-inssa-teal text-xs font-medium md:w-[180px] md:h-[50px] md:text-lg xl:text-2xl xl:w-[250px] xl:h-[70px] xl:rounded-xl xl:border-2">
                        <FontAwesomeIcon
                          icon={faAppleBrand}
                          size="lg"
                          className="mr-1 xl:mr-2.5"
                        />
                        <span>App store</span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div className="h-full w-full absolute top-0 inset-0 bg-gradient-to-t from-[#000000] to-[#00000060] z-0"></div>
            </section>
            <section className="mb-9 md:mb-[60px] xl:mb-[70px] ">
              <GameDetailCarousel
                slides={gameData?.images?.map((img) => (
                  <>
                    <div
                      key={img}
                      className="h-full bg-cover bg-center bg-no-repeat aspect-video rounded-2xl"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_CF_URL}${img})`,
                      }}
                    ></div>
                  </>
                ))}
                options={{
                  align: "start",
                  dragFree: true,
                  containScroll: "trimSnaps",
                }}
              />
            </section>
            <section className="px-4 text-xs font-medium flex flex-col mb-[106px] md:px-[26px] md:text-lg md:mb-[136px] xl:text-2xl xl:px-0 xl:flex xl:items-center xl:mb-[276px] xl:pl-3.5">
              <div className="w-full flex flex-col xl:w-100">
                {gameData?.description &&
                  gameData.description.split("\n\n").map((data) => (
                    <>
                      <br />
                      <div className="first:text-inssa-teal">
                        {data.split("\n").map((sentence) => (
                          <>
                            <br />
                            <span className="text-neutral-50">{sentence}</span>
                          </>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </section>
            <section className="px-4 font-medium flex flex-col items-center mb-[186px] md:mb-[200px]">
              <span className="font-bold mb-6 md:text-xl md:mb-14 xl:text-4xl">
                {questionData?.visible && questionData?.content}
              </span>
              <div className="grid grid-cols-1 gap-y-2 mb-[26px] w-full text-xs font-medium md:w-[440px] md:text-sm md:gap-y-3 md:mb-9 xl:w-[764px] xl:text-2xl">
                {questionData?.visible &&
                  questionData?.choices.map((choice) => (
                    <div>
                      <input
                        type="checkbox"
                        id={"" + choice.id}
                        className="hidden peer"
                      />
                      <label
                        htmlFor={"" + choice.id}
                        className="border border-inssa-teal h-7 rounded-md flex justify-center items-center md:h-9 xl:h-16 xl:rounded-xl xl:mb-5 cursor-pointer active:bg-inssa-teal active:text-neutral-800 peer-checked:bg-inssa-teal peer-checked:text-neutral-800"
                        onClick={() => clickChoice(choice.id)}
                        key={choice.id}
                      >
                        <span>{choice.content}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <span className="text-neutral-300 text-[10px] md:text-sm xl:text-base">
                본 설문조사를 참여하셔야 사전예약이 가능합니다.
              </span>
            </section>
            <section className="flex flex-col items-center font-bold pb-[347px] relative md:pb-[610px]">
              {postSuccess && (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  size="2xl"
                  className="mb-4 text-inssa-teal bg-neutral-50 rounded-full"
                />
              )}
              <span className="text-2xl mb-3 md:text-[32px] md:mb-[22px]">
                사전예약
              </span>
              <span className="text-neutral-600 mb-5 md:text-lg md:mb-[38px]">
                사전예약 하고 리워드 받아가자!
              </span>
              {postSuccess ? (
                <div
                  className="flex justify-center items-center bg-inssa-teal w-[320px] h-11 rounded-md md:w-[428px] md:h-[68px] cursor-pointer"
                  onClick={handleSubmit(onValid)}
                >
                  <span className="text-neutral-800 text-sm md:text-xl">
                    신청이 완료되었습니다!
                  </span>
                </div>
              ) : (
                <form>
                  <input
                    type="text"
                    className="appearance-none h-11 w-[320px] font-medium rounded-md pl-5 text-neutral-800 placeholder:text-xs placeholder:text-neutral-400 focus:outline-none md:w-[428px] md:text-base md:placeholder:text-base md:h-[68px]"
                    placeholder="전화번호를 입력하세요 ex)01012345678"
                    {...register("phone", {
                      required: true,
                      pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                    })}
                  />
                  <div className="text-[10px] font-normal text-neutral-400 flex justify-between items-center py-4 md:text-sm md:py-5">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-1"
                        {...register("agreement", {
                          required: true,
                        })}
                      />
                      <span>개인정보 수집이용 및 SMS 수신에 동의합니다.</span>
                    </div>
                    <span
                      className="underline cursor-pointer"
                      onClick={openModal}
                    >
                      자세히 보기
                    </span>
                  </div>
                  <div
                    className="flex justify-center items-center bg-inssa-teal w-[320px] h-11 rounded-md md:w-[428px] md:h-[68px] cursor-pointer"
                    onClick={handleSubmit(onValid)}
                  >
                    <span className="text-neutral-800 text-sm md:text-xl">
                      사전예약 신청하기
                    </span>
                  </div>
                  {postFail !== "" && <span>postFail</span>}
                </form>
              )}
              {gameData?.rewardImagesCnt === 0 ? null : (
                <div className="flex justify-between w-[320px] mt-[22px] mb-0.5 text-sm text-neutral-400 md:mt-14 md:w-[428px] md:text-xl">
                  <div className="w-20 h-20 rounded-full bg-neutral-800 border border-neutral-900 flex justify-center items-center md:w-[122px] md:h-[122px]">
                    <span className="">보상</span>
                  </div>
                  <div className="w-20 h-20 rounded-full bg-neutral-800 border border-neutral-900 flex justify-center items-center md:w-[122px] md:h-[122px]">
                    <span className="">보상</span>
                  </div>
                  <div className="w-20 h-20 rounded-full bg-neutral-800 border border-neutral-900 flex justify-center items-center md:w-[122px] md:h-[122px]">
                    <span className="">보상</span>
                  </div>
                </div>
              )}
              <Link
                to={"/"}
                className="flex justify-center items-center bg-neutral-800 border border-neutral-900 w-[320px] h-11 rounded-md mt-[30px] mb-5 md:mt-20 md:w-[428px] md:h-[68px] md:mb-6"
              >
                <span className="text-xs md:text-base">
                  더 다양한 게임 사전예약 하기
                </span>
              </Link>
              <span className="text-neutral-300 text-[10px] font-medium md:text-sm">
                번호당 한번만 예약할 수 있습니다.
              </span>
              <span className="text-neutral-500 text-[10px] mt-5 font-medium md:text-sm md:mt-6">
                © 2023 PLITHUS Inssa
              </span>
              <div className="absolute -bottom-0 w-full bg-gradient-to-t from-[#0C997F] h-16 z-10 md:h-20 md:from-[#0EAB8D]"></div>
            </section>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="ConfirmModal"
          >
            <div className="flex flex-col items-end z-50">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="lg"
                className="text-inssa-teal cursor-pointer"
                onClick={closeModal}
              />
              <div className="flex flex-col  w-full">
                <span className="text-neutral-700 text-3xl font-bold mb-14">
                  개인정보수집 이용동의
                </span>
                <span>
                  (주)플리더스는 인디게임 사전예약 서비스 이용을 위해
                  <br /> 다음과 같이 고객님의 정보를 수집 및 이용합니다.
                  <br /> 개인정보 수집, 이용에 대한 동의를 거부할 수 있으며,
                  <br /> 해당 동의를 거부시 사전예약 서비스를 이용할 수
                  없습니다.
                  <br />
                  <br /> 1.개인 정보 수집 및 이용 동의 안내
                  <br />
                  <br /> *개인정보 수집범위
                  <br /> -연락처
                  <br /> *개인정보 수집 및 이용목적
                  <br /> -인디게임 사전예약 참여, 게임 출시 알림
                  <br /> *개인정보 보유 및 이용기간
                  <br /> -개인정보는 수집 및 이용 목적 달성 시까지 보유하며,
                  <br /> 이용목적 달성 후 파기하는 것을 원칙으로함
                  <br />
                  <br />
                  2. 개인정보 제공 및 이용 안내 동의
                  <br />
                  <br /> *제공업체 : (주)플리더스 외 제휴 게임사
                  <br /> *개인정보 제공 목적 : 게임출시 및 보상 알림
                  <br /> (문자 혹은 카톡으로 전달)
                </span>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default GameDetail;
