import axios from "axios";

interface IChoice {
  id: number;
  content: string;
  visible: true;
}

export interface IGameQuestion {
  choices: IChoice[];
  choicesCnt: number;
  content: string;
  id: number;
  visible: boolean;
}

export interface IGameDetail {
  id: number;
  title: string;
  gameCompany: string;
  subDescription: string;
  description: string;
  bannerUrl: string;
  bgImg: string;
  googlePlayUrl: string;
  appStoreUrl: string;
  status: string;
  recommendation: boolean;
  visible: boolean;
  imagesCnt: number;
  images: string[];
  rewardImagesCnt: number;
  rewardImages: string[];
  genres: string[];
  totSurveyors: number;
  expiredAt: string;
}

export const getGameDetail = async (id: string) => {
  const paramId = +id;
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/games/${paramId}`).then((response) =>
    response.json()
  );
};

export const getGameQuestion = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/questions/visible`).then((response) =>
    response.json()
  );
};

export const postSurvey = async (formData: any) => {
  return await axios({
    headers: {
      "Content-Type": "application/json",
    },
    url: `${process.env.REACT_APP_BASE_PATH}/v1/surveyors`,
    method: "POST",
    data: JSON.stringify(formData),
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
