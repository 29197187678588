import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import emblaCarouselAutoplay from "embla-carousel-autoplay";
import { IBannerResult } from "../../api/main/mainApi";
import { Link } from "react-router-dom";

type PropType = {
  options?: EmblaOptionsType;
  slides: IBannerResult[] | undefined;
};

export const MainCarousel = (props: PropType) => {
  const autoplayOptions = {
    delay: 5000,
    rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement,
    stopOnInteraction: false,
  };

  const { options, slides } = props;
  const [emblaRef, embla] = useEmblaCarousel(options, [
    emblaCarouselAutoplay(autoplayOptions),
  ]);
  const [selectedIndex, setSelectedindex] = useState(0);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedindex(embla.selectedScrollSnap());
  }, [embla, setSelectedindex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <div className="overflow-hidden h-full relative" ref={emblaRef}>
      <div className="flex flex-row h-full">
        {Array.isArray(slides)
          ? slides?.map((slide) => (
              <>
                <Link
                  to={slide.bannerUrl}
                  key={slide.id}
                  className="flex-[0_0_100%] h-full"
                >
                  <div
                    className="h-full bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_CF_URL}${slide.bannerImgUrl})`,
                    }}
                  ></div>
                </Link>
              </>
            ))
          : null}
      </div>
      <div className="w-full flex justify-center absolute bottom-4 right-6 md:bottom-6 md:right-9 xl:bottom-9 xl:right-9">
        <div className="flex justify-end text-white w-full mr-4 md:mr-6 xl:w-[1280px] xl:mr-0">
          <div className="flex justify-center items-center text-xs xl:text-sm px-3 py-1 xl:px-4 bg-[#0e0e0e72] rounded-xl xl:rounded-full">
            <span className="text-white">{selectedIndex + 1}</span>
            <span className="text-[#ffffffb2]"> / </span>
            <span className="text-[#ffffffb2] ">{slides?.length }</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCarousel;
