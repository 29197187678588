import React from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { IBannerResult } from "../api/main/mainApi";
import { Link } from "react-router-dom";

type PropType = {
  options?: EmblaOptionsType;
  slides: IBannerResult[] | undefined;
};

const AdCarousel = (props: PropType) => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);
  const { slides } = props;

  return (
    <div className="embla overflow-hidden h-full" ref={emblaRef}>
      <div className="embla__container flex h-full">
        {slides?.map((slide) => (
          <>
            <Link
              to={slide.bannerUrl}
              key={slide.id}
              className="flex-[0_0_100%] h-full"
            >
              <div
                className="h-full bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_CF_URL}${slide.bannerImgUrl})`,
                }}
              ></div>
            </Link>
          </>
        ))}
      </div>
    </div>
  );
};

export default AdCarousel;
