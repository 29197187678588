import { Link, useLocation } from "react-router-dom";

const logo = require("../assets/images/logo.png");

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <nav className="flex justify-center shadow-[0_4px_12px_rgba(0,0,0,0.03)] relative z-10 xl:items-center">
      <div className="font-medium text-neutral-300 w-full xl:h-[68px] xl:w-100">
        <div className="flex flex-col items-center pt-7 md:flex-row md:items-end md:pb-6 xl:pt-0 xl:pb-0 xl:h-full xl:items-center">
          <Link to={"/"}>
            <img
              src={logo}
              alt=""
              className="w-[84px] h-[42px] mb-4 md:w-[116px] md:h-[60px] md:mb-0 md:ml-6 xl:w-[76px] xl:h-[40px] xl:ml-0"
            />
          </Link>
          {/* <div className="grid grid-cols-2 h-[50px] w-full cursor-pointer md:flex md:text-lg md:ml-16 xl:h-full xl:ml-9">
            <Link
              to={"/reservation"}
              className="flex justify-center items-center hover:text-inssa-teal md:mr-9"
            >
              <div className="">
                <span
                  className={
                    pathname === "/reservation" ? "text-inssa-teal" : ""
                  }
                >
                  사전예약
                </span>
              </div>
            </Link>
            <Link
              to={"/released-game"}
              className="flex justify-center items-center hover:text-inssa-teal"
            >
              <div>
                <span
                  className={
                    pathname === "/released-game" ? "text-inssa-teal" : ""
                  }
                >
                  출시된 게임
                </span>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
