import { useQuery } from "react-query";
import RecommandCarousel from "../components/main/recommandCarousel";
import MainCarousel from "../components/main/mainCarousel";
import Navigation from "../components/Navigation";
import ListItem from "../components/ListItem";
import Footer from "../components/Footer";
import {
  IBannerResult,
  IGameResult,
  IRecommendGameContent,
  getGames,
  getMainBanners,
  getRecommandGames,
  getSubBanners,
} from "../api/main/mainApi";
import AdCarousel from "../components/AdCarousel";
import Loader from "../components/Loader";

const Main = () => {
  const { data: mBannerData, isLoading: mBannerLoading } = useQuery<
    IBannerResult[]
  >("getMainBanners", () => getMainBanners());

  const { data: sBannerData, isLoading: sBannerLoading } = useQuery<
    IBannerResult[]
  >("getSubBanners", () => getSubBanners());

  const { data: recommandGameData, isLoading: recommandGameLoading } = useQuery<
    IRecommendGameContent[]
  >("getRecommandGames", () => getRecommandGames());

  const { data: gameData, isLoading: isGameLoading } = useQuery<IGameResult>(
    "getGames",
    () => getGames()
  );

  return (
    <>
      <Navigation />
      {mBannerLoading &&
      sBannerLoading &&
      recommandGameLoading &&
      isGameLoading ? (
        <Loader />
      ) : (
        <>
          <section className="w-full cursor-pointer mb-14 md:mb-[74px] xl:mb-[88px] h-[226px] md:h-[398px] xl:h-[500px] -z-10">
            <MainCarousel
              slides={mBannerData}
              options={{
                align: "start",
                loop: true,
              }}
            />
          </section>
          <section className="mt-16 flex flex-col items-center text-neutral-900">
            <span className="font-bold mb-3 text-lg md:text-2xl xl:text-[32px]">
              Inssa! 추천 게임
            </span>
            <span className="font-medium text-xs text-neutral-500 mb-11 md:text-base xl:text-xl xl:mb-[60px]">
              사전예약 진행중인 게임을 한번에 확인하고 바로 신청하자!
            </span>
            <div className="relative w-full pl-4 md:pl-6 xl:ml-0 xl:w-[1280px] mb-[68px] md:mb-[74px] xl:mb-[84px]">
              <RecommandCarousel
                slides={recommandGameData}
                options={{
                  align: "start",
                  dragFree: true,
                  containScroll: "trimSnaps",
                }}
              ></RecommandCarousel>
            </div>
          </section>
          <section className="flex justify-center">
            <div className="w-full cursor-pointer bg-cover bg-no-repeat bg-center overflow-hidden rounded-lg md:rounded-xl h-[110px] mx-4 md:h-[130px] md:mx-6 xl:h-[170px] xl:w-[1280px]">
              <AdCarousel slides={sBannerData} />
            </div>
          </section>
          <div className="h-16 md:h-[92px] xl:h-[100]"></div>
          <section className="flex justify-center">
            <div className="text-neutral-800 font-medium px-4 w-full mb-[140px] md:mb-[200px] xl:mb-[300px] md:px-6 xl:w-100 xl:px-0">
              <div className="mb-1.5 md:mb-4">
                <span className="text-xs md:text-lg">
                  총 {gameData?.count}개
                </span>
              </div>
              <ListItem gameData={gameData} />
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default Main;
