const Footer = () => {
  return (
    <footer className="border-t border-neutral-200 flex justify-center">
      <div className=" py-[18px] px-4 text-neutral-800 text-[10px] font-medium md:px-6 xl:py-10 xl:w-100">
        <div className="md:flex">
          <div className="flex mb-1.5 mr-1">
            <span className="font-bold mr-1 xl:text-sm xl:mr-1.5">회사명</span>
            <span className="text-neutral-600 mr-2.5 xl:text-sm xl:mr-6">
              (주) 플리더스
            </span>
            <span className="font-bold mr-1 xl:text-sm xl:mr-1.5">주소</span>
            <span className="text-neutral-600 mr-2.5 xl:text-sm xl:mr-6">
              서울 서대문구 명지 2길 33 101호
            </span>
            <span className="font-bold mr-1 xl:text-sm xl:mr-1.5">대표</span>
            <span className="text-neutral-600 mr-2.5 xl:text-sm xl:mr-6">
              임찬영
            </span>
          </div>
          <div className="mb-1.5">
            <span className="font-bold mr-1 xl:text-sm xl:mr-1.5">
              사업자등록번호
            </span>
            <span className="text-neutral-600 xl:text-sm">726-88-02485</span>
          </div>
        </div>
        <div className="mb-4 xl:mb-6">
          <span className="text-neutral-300 xl:text-sm">
            © 2023 PLITHUS Inssa
          </span>
        </div>
        <div className="mb-4">
          <p className="text-neutral-400 xl:text-xs">
            플리더스는 플랫폼 제공자로서 게임 제작에 당사자가 아니며, 직접적인
            게임 제작을 진행하지 않습니다. 게임 제작의 책임은 해당 게임사에게
            있으며, 게임 제작과 관련하여 유저와 발생하는 모든 법적 분쟁에 대한
            책임은 해당 게임사가 부담합니다.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
