import Ad from "../components/Advertisement";
import Footer from "../components/Footer";
import ListItem from "../components/ListItem";
import Navigation from "../components/Navigation";
import CategoriesCarousel from "../components/games/categoriesCarousel";
import CategoriesCarouselSlide from "../components/games/categoriesCarouselSlide";

const Reservation = () => {
  return (
    <>
      <Navigation />
      <div className="h-[18px] w-full md:h-7 xl:h-10"></div>
      <Ad />
      <section className="pl-4 pt-[38px] pb-14 md:pl-11 md:pt-[60px] mb:pb-[70px] xl:flex xl:justify-center">
        <CategoriesCarousel
          slides={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((index) => (
            <CategoriesCarouselSlide key={index} />
          ))}
          options={{
            align: "start",
            dragFree: true,
            slidesToScroll: "auto",
            containScroll: "trimSnaps",
          }}
        ></CategoriesCarousel>
      </section>
      <section className="flex justify-center">
        <div className="text-neutral-800 font-medium px-4 w-full mb-[140px] md:mb-[200px] xl:mb-[300px] md:px-6 xl:w-100 xl:px-0">
          <div className="mb-1.5 md:mb-4">
            <span className="text-xs md:text-lg">총 77개</span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Reservation;
