import React from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";

type PropType = {
  options?: EmblaOptionsType;
  slides: JSX.Element[] | undefined;
};

export const DetailCarousel = (props: PropType) => {
  const { options, slides } = props;
  const [emblaRef] = useEmblaCarousel(options);

  return (
    <div className="xl:flex xl:justify-center">
      <div className="relative overflow-hidden h-full xl:w-100" ref={emblaRef}>
        <div className="flex flex-row h-full xl:-ml-6">
          {slides?.map((slide, index) => (
            <div
              className="flex-[0_0_70%] h-full pl-4 md:flex-[0_0_36%] xl:flex-[0_0_33%] md:pl-6"
              key={index}
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailCarousel;