import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { IRecommendGameContent } from "../../api/main/mainApi";
import RecommandCarouselSlide from "./recommandCarouselSlide";

type PropType = {
  options?: EmblaOptionsType;
  slides: IRecommendGameContent[] | undefined;
};

export const RecommandCarousel = (props: PropType) => {
  const { options, slides } = props;
  const [emblaRef, embla] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
    window.addEventListener("resize", () => onSelect());
  }, [embla, onSelect]);

  if (typeof slides !== "undefined") {
    return (
      <>
        <div className="relative overflow-hidden h-full w-full" ref={emblaRef}>
          <div className="flex flex-row w-full h-full xl:-ml-6">
            {slides?.map((slide, index) => (
              <div
                className="flex-[0_0_70%] w-full h-full relative mr-4 md:flex-[0_0_35%] md:mr-6 xl:flex-[0_0_25%] xl:pl-6 xl:mr-0"
                key={index}
              >
                <RecommandCarouselSlide
                  id={slide.id}
                  title={slide.title}
                  gameCompany={slide.gameCompany}
                  subDescription={slide.subDescription}
                  bannerUrl={slide.bannerUrl}
                  totSurveyors={slide.totSurveyors}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="hidden xl:block">
          <button
            className=" bg-neutral-50 w-16 h-16 rounded-full flex justify-center items-center absolute -left-7 top-1/3 z-10 shadow-xl disabled:hidden"
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="h-8 w-8 text-neutral-800 cursor-pointer"
            />
          </button>
          <button
            className="bg-neutral-50 w-16 h-16 rounded-full flex justify-center items-center absolute -right-7 top-1/3 z-10 shadow-xl disabled:hidden"
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className="h-8 w-8 text-neutral-800 cursor-pointer"
            />
          </button>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};

export default RecommandCarousel;
