import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorComponent from "./components/ErrorComponent";
import NotFound from "./screens/NotFound";
import Main from "./screens/Home";
import Reservation from "./screens/Reservation";
import ReleasedGame from "./screens/ReleasedGame";
import GameDetail from "./screens/games/gameDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Main />,
        errorElement: <ErrorComponent />,
      },
      {
        path: "reservation",
        element: <Reservation />,
        errorElement: <ErrorComponent />,
      },
      {
        path: "released-game",
        element: <ReleasedGame />,
        errorElement: <ErrorComponent />,
      },
      {
        path: "games/:gameId",
        element: <GameDetail />,
        errorElement: <ErrorComponent />,
      }
    ],
    errorElement: <NotFound />,
  },
]);

export default router;
