interface SlideProps {
  key: number;
}

export default function CategoriesCarouselSlide({ key }: SlideProps) {
  return (
    <div className="flex flex-col items-center" key={key}>
      <div className="w-[48px] h-[48px] bg-cover bg-center bg-no-repeat relative cursor-pointer rounded border border-neutral-200 bg-neutral-50 md:w-[68px] md:h-[68px] xl:w-[107px] xl:h-[107px]"></div>
      <span className="pt-2 text-neutral-400 text-xs md:pt-3 xl:text-lg xl:pt-6 xl:text-neutral-800">
        전체보기
      </span>
    </div>
  );
}
