export interface IBannerResult {
  id: number;
  bannerUrl: string;
  bannerImgUrl: string;
  main?: boolean;
}

export interface IRecommendGameContent {
  id: number;
  title: string;
  gameCompany: string;
  subDescription: string;
  bannerUrl: string;
  status: string;
  totSurveyors: number;
}

interface IGameContent {
  id: number;
  title: string;
  gameCompany: string;
  subDescription: string;
  bannerUrl: string;
  status: string;
  totSurveyors: number;
}

export interface IGameResult {
  count: number;
  contents: IGameContent[];
}

export const getMainBanners = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/banners/main`).then((response) =>
    response.json()
  );
};

export const getSubBanners = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/banners/sub`).then((response) =>
    response.json()
  );
};

export const getRecommandGames = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/games/recommendation`).then((response) =>
    response.json()
  );
};

export const getGames = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_PATH}/v1/games`).then((response) =>
    response.json()
  );
};
